<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">文章定義作成</span>
      </div>
      <form class="cpx-15" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >管理タイトル</label
          >
          <div class="cp-4 w-50 pt-3">
            <input class="form-input col-sm-12" v-model="title" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">
            所持する情報に基づいて表示させる文章を制作
          </label>
          <div
            v-for="(value, index) in textCondition"
            :key="index"
            class="cp-4 pt-3 col-sm-7 d-flex"
          >
            <input class="form-input" v-model="value.text" />
            <b-icon
              icon="gear-fill"
              class="ml-3"
              font-scale="2.5"
              style="color: #333"
              v-on:click="settingCondition(value, index)"
            />
            <b-button
              @click="removeListDisplay(index)"
              style="width: 38px; margin-right: 10px; color: white"
              class="bg-333333 ml-3"
            >
              <font-awesome-icon
                class="sidebar-icon"
                :icon="['fas', 'minus']"
              />
            </b-button>
          </div>
          <div class="text-center" style="width: 57.5%">
            <b-icon
              icon="plus"
              class="h1 rounded-circle add-new-description"
              @click="addText()"
              font-scale="3"
              style="color: #fff; background: #333"
            ></b-icon>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">
            表示条件に該当しないユーザーに表示する文字
          </label>
          <div class="cp-4 w-50 pt-3">
            <input class="form-input col-sm-12" v-model="textNoCondition" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >取得する際のパラメータを設定する（英数字）
          </label>
          <div class="cp-4 pt-3 d-flex align-items-center" style="width: 48%">
            {%
            <input class="form-input col-sm-12" v-model="param" />
            %}
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4 py-4">
          <b-button
            v-on:click.prevent="CreateSentence()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
          >
            <span>登録完了</span>
          </b-button>
          <b-button variant="secondary" class="mr-4" v-on:click="returnList()"
            >戻る</b-button
          >
        </div>
      </form>
    </div>
    <b-modal
      id="setting-condition"
      modal-class="setting-condition"
      ref="setting-condition"
      size="lg"
      title="ユーザーが所持する情報を設定"
      hide-footer
    >
      <div
        class="ml-5 mb-4"
        v-for="(val, index) in conditions"
        v-bind:key="index"
      >
        <div class="d-flex align-items-center mb-2">
          <div>
            <b-form-radio-group
              v-model="val.is_content_slag"
              :options="typeConditions"
              :name="'plain' + index"
              @change="changeTypeCondition(val, index)"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <div class="ml-4 mr-4" style="width: 60%">
            <div
              class="custom-select-2 w-100 custome-multi-slag m-0"
              v-if="val.is_content_slag == 1"
            >
              <multiselect
                v-model="val.id_check"
                tag-placeholder="コンテンツ追加"
                placeholder="コンテンツ追加"
                label="text"
                track-by="value"
                :options="listContentOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
                select-label="入力して選択する"
                deselect-label="入力して削除する"
                selected-label="選択された"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
            <div class="custom-select-2 w-100 custome-multi-slag m-0" v-else>
              <multiselect
                v-model="val.id_check"
                tag-placeholder="スラグ追加"
                placeholder="スラグ追加"
                label="slag_name"
                track-by="slag_id"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
          </div>
          <div>
            <b-form-radio-group
              v-model="val.is_owned"
              :options="typeOwn"
              :name="'plain1' + index"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <b-button
            v-if="conditions.length > 1"
            variant="dark"
            class="rounded-circle custome-btn-sub float-right mt-0 ml-4 bt-sub"
            style="border-radius: 50% !important"
            v-on:click="subCondition(index)"
            ><b-icon icon="dash" scale="2"
          /></b-button>
        </div>
        <div>
          <b-button class="mr-4 add-btn" v-on:click="addCondition(index)">
            and設定追加
          </b-button>
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <b-button
          @click="saveCondition()"
          class="mr-4 btn-color sale-btn text-white"
        >
          <span>登録完了</span>
        </b-button>
        <b-button @click="closeSaveCondition()"> 戻る </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants.js";

export default {
  name: "CreateSentence",
  data() {
    return {
      isLoading: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      title: "",
      textCondition: [{}],
      textNoCondition: "",
      param: "",
      listSlagOptions: [],
      listContentOptions: [],
      conditions: [
        {
          id_check: [],
          is_content_slag: 0,
          is_owned: 0,
        },
      ],
      typeConditions: [
        { text: "コンテンツ", value: 1 },
        { text: "スラグ", value: 0 },
      ],
      typeOwn: [
        { text: "所持", value: 1 },
        { text: "未所持", value: 0 },
      ],
    };
  },
  async created() {
    const { params } = this.$route;

    const requestContent = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    await this.getListContents(requestContent);
    const requestSlag = {
      shop_id: this.shop_id,
    };
    await this.getALLlistSlag(requestSlag);

    if (params.id !== undefined) {
      const req = {
        id: params.id,
        shop_id: this.shop_id,
      };
      await this.showSentence(req);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listSlag",
      "listContents",
      "detailSentence",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listContents() {
      this.listContentOptions = this.listContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
    detailSentence() {
      if (Object.keys(this.detailSentence).length) {
        this.title = this.detailSentence.title;
        this.textNoCondition = this.detailSentence.text_no_condition;
        this.param = this.detailSentence.param;

        if (
          this.detailSentence.text_condition &&
          this.detailSentence.text_condition.length > 0
        ) {
          this.detailSentence.text_condition.forEach((item, index) => {
            if (item.length > 0) {
              let arrGiveCondition = this.convertContent(item["id_give"]);
              this.detailFixedPage.content_give_condition[index]["id_give"] =
                arrGiveCondition;

              let arrCondition = [];
              if (item["condition"]) {
                item["condition"].forEach((itemC, indexC) => {
                  if (itemC["is_content_slag"] == 1) {
                    arrCondition = this.convertContent(itemC["id_check"]);
                  } else {
                    arrCondition = this.convertSlag(itemC["id_check"]);
                  }

                  this.detailFixedPage.content_give_condition[index][
                    "condition"
                  ][indexC]["id_check"] = arrCondition;
                });
              }
            }
          });
          this.textCondition = Object.values(
            this.detailSentence.text_condition
          );
        }
      }
    },
  },
  methods: {
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListContents: "getListContents" }),
    ...mapActions({ createSentence: "createSentence" }),
    ...mapActions({ showSentence: "showSentence" }),
    ...mapActions({ editSentence: "editSentence" }),
    async CreateSentence() {
      this.isLoading = true;

      let shop_id = this.shop_id;
      const formCreate = {
        id: this.$route.params.id,
        shop_id: shop_id,
        title: this.title,
        text_condition: this.textCondition,
        text_no_condition: this.textNoCondition,
        param: this.param,
      };

      if (formCreate.id === undefined) {
        const request = await this.createSentence(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      } else {
        const request = await this.editSentence(formCreate);
        if (request.success === true) {
          this.$router.go(-1);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    settingCondition(item, index, type) {
      this.indexBlock = index;
      this.typeBlock = type;
      let conditions = [
        {
          id_check: [],
          is_content_slag: 0,
          is_owned: 0,
        },
      ];
      if (
        !this.textCondition[this.indexBlock]["condition"] ||
        this.textCondition[this.indexBlock]["condition"].length == 0
      ) {
        this.conditions = conditions;
      } else {
        this.conditions = this.textCondition[this.indexBlock]["condition"];
      }
      this.$bvModal.show("setting-condition");
    },
    addText() {
      this.textCondition.push({});
    },
    addCondition(index) {
      this.conditions.splice(index + 1, 0, {
        id_check: [],
        is_content_slag: 0,
        is_owned: 0,
      });
    },
    subCondition(index) {
      this.conditions.splice(index, 1);
    },
    saveCondition() {
      this.textCondition[this.indexBlock]["condition"] = this.conditions;
      this.$bvModal.hide("setting-condition");
    },
    closeSaveCondition() {
      this.$bvModal.hide("setting-condition");
    },
    removeListDisplay(index) {
      this.textCondition.splice(index, 1);
    },
    changeTypeCondition(value) {
      value.id_check = [];
    },
    convertContent(array) {
      array.forEach((subItem, subIndex) => {
        this.listContents.map((value) => {
          if (value.id == subItem.value) {
            array[subIndex] = {
              value: value.id,
              text: value.title,
            };
          }
        });
      });
      return array;
    },
    convertSlag(array) {
      array.forEach((subItem, subIndex) => {
        this.listSlag.map((value) => {
          if (value.id == subItem.slag_id) {
            array[subIndex] = {
              slag_id: value.id,
              slag_name: value.slag_name ? value.slag_name : value.slag_name_en,
            };
          }
        });
      });
      return array;
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.btn-share-freesize-active,
.btn-share-freesize {
  width: 150px;
}
</style>
